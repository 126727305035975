var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"mew-component--aave-summary pa-4 pa-md-12",attrs:{"rounded":"","color":"white","elevation":"1","width":_vm.$vuetify.breakpoint.mdAndUp ? '650px' : '100%'}},[(_vm.isDeposit && _vm.step === 3)?_c('v-card',{staticClass:"d-flex align-center justify-space-between pa-7 mb-6",attrs:{"flat":"","color":"overlayBg"}},[_c('div',{staticClass:"d-flex flex-column align-start"},[_c('span',{staticClass:"mew-heading-3 textPrimaryModule--text mb-2"},[_vm._v("Amount to Deposit")]),_c('span',{staticClass:"mew-heading-1 mb-2"},[_vm._v(_vm._s(_vm.amount)+" "+_vm._s(_vm.selectedToken.token))]),_c('span',{staticClass:"textPrimaryModule--text"},[_vm._v(_vm._s(_vm.amountUsd))])]),_c('img',{attrs:{"height":_vm.$vuetify.breakpoint.mdAndUp ? '80' : '30',"src":_vm.selectedToken.tokenImg,"alt":_vm.selectedToken.token}})]):_vm._e(),(_vm.isInterest)?_c('div',{staticClass:"d-flex align-center justify-space-between mb-10"},[_c('v-card',{staticClass:"d-flex flex-column pa-10 text-left",attrs:{"flat":"","color":"overlayBg"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Current Interest Type")]),_c('span',{class:[
          'mew-heading-2 my-3',
          _vm.getInterestTypeClass(_vm.currentInterest.type)
        ]},[_vm._v(_vm._s(_vm.currentInterest.percentage))]),_c('span',{class:[
          'font-weight-bold',
          _vm.getInterestTypeClass(_vm.currentInterest.type)
        ]},[_vm._v(_vm._s(_vm.currentInterest.type))])]),_c('v-icon',[_vm._v("mdi-arrow-right")]),_c('v-card',{staticClass:"d-flex flex-column pa-10 text-left",attrs:{"flat":"","color":"overlayBg"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Next Interest Type")]),_c('span',{class:[
          'mew-heading-2 my-3',
          _vm.getInterestTypeClass(_vm.nextInterest.type)
        ]},[_vm._v(_vm._s(_vm.nextInterest.percentage))]),_c('span',{class:['font-weight-bold', _vm.getInterestTypeClass(_vm.nextInterest.type)]},[_vm._v(_vm._s(_vm.nextInterest.type))])])],1):_vm._e(),(_vm.isInterest)?_c('v-divider'):_vm._e(),_vm._l((_vm.details),function(detail,idx){return _c('v-row',{key:idx,staticClass:"d-flex align-center"},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"6"}},[_c('span',[_vm._v(_vm._s(detail.title))]),(detail.tooltip)?_c('mew-tooltip',{staticClass:"ml-1",attrs:{"text":detail.tooltip}}):_vm._e()],1),_c('v-col',{staticClass:"font-weight-bold d-flex align-center justify-end",attrs:{"cols":"6"}},[(detail.icon)?_c('img',{staticClass:"mr-1",attrs:{"src":detail.icon,"height":"20"}}):_vm._e(),(detail.indicator)?_c('v-icon',{class:detail.class,attrs:{"dense":""}},[_vm._v(_vm._s(detail.indicator))]):_vm._e(),_c('span',{class:detail.class},[_vm._v(_vm._s(detail.value))])],1)],1)}),(_vm.isInterest)?_c('v-divider',{staticClass:"mt-5"}):_vm._e(),_c('mew-button',{staticClass:"mt-10 mx-auto d-block",attrs:{"title":"Confirm","btn-size":"xlarge"},nativeOn:{"click":function($event){return _vm.confirm.apply(null, arguments)}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }