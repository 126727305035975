/**
 * Currency types
 */
export default {
  USD: 'USD',
  EUR: 'EUR',
  CHR: 'CHR',
  SGD: 'SGD',
  RUB: 'RUB',
  RMB: 'RMB'
};
